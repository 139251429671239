<template>
  <v-card elevation="0" rounded="lg" outlined class="pa-0 mx-0 mt-8">
    <v-card-title class="text-h5 font-weight-bold"> Settings </v-card-title>
    <v-card-text class="pa-5">
      <v-row justify="center" align="center">
        <v-col cols="12" md="7">
          <v-card outlined elevation="0">
            <v-btn
              class="layer-above"
              absolute
              right
              v-if="isEditing"
              icon
              @click="openImage"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="require('../../assets/images/icon/Edit.svg')"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span> Edit Company Image </span>
              </v-tooltip>
            </v-btn>
            <v-img
              width="600px"
              contain
              max-height="400px"
              :src="
                currentImage ||
                require('../../assets/images/recruiter/default.svg')
              "
            >
              <Loader v-if="imageEdit"></Loader>
            </v-img>
            <input
              type="file"
              ref="avatarFile"
              style="display: none"
              accept="image/png"
              @change="uploadAttachmentSave"
            />
          </v-card>
        </v-col>
        <v-col cols="12" md="5" class="text-body-1 d-flex flex-column">
          <v-row>
            <v-col>
              <div class="font-weight-medium black--text">Hiring Plan:</div>
            </v-col>
            <v-col>
              <div class="black--text">{{ hiringPlan }}</div>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col>
              <div class="font-weight-medium black--text">Company Name:</div>
            </v-col>
            <v-col>
              <v-text-field
                v-if="isEditing"
                dense
                :hidden="!isEditing"
                v-model="updatedName"
                :value="updatedName"
              >
              </v-text-field>
              <div class="black--text" v-else>
                {{ customer?.getName() || 'Not Set' }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-row>
            <div class="pl-3 text-h6 font-weight-bold black--text">
              Email Domains
            </div>
          </v-row>
          <v-row class="pl-3" v-if="isEditing" :hidden="!isEditing">
            <v-chip-group column>
              <v-chip
                v-for="(domain, idx) in emailDomains"
                :key="idx"
                color="success"
                close
                @click:close="removeEmailDomain(idx)"
              >
                {{ domain }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row class="pl-3" v-else>
            <v-chip-group column>
               <v-chip v-for="domain in emailDomains" :key="domain" color="success">
                {{ domain }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-if="isEditing" :hidden="!isEditing">
            <v-col cols="3">
              <v-text-field
                ref="emailDomainInput"
                outlined
                dense
                v-model="newEmailDomain"
                :value="newEmailDomain"
                @keyup.enter="addEmailDomain"
                placeholder="enter email domain"
                :rules="[
                  (v) => v.length > 0 || 'Email domain can not be empty',
                  (v) =>
                    !emailDomains.includes(v) || 'Email domain already exists',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                text
                @click="addEmailDomain"
                color="primary"
                outlined
                :disabled="
                  newEmailDomain.length === 0 ||
                  emailDomains.includes(newEmailDomain)
                "
              >
                <v-icon left>
                  {{ mdiPlus }}
                </v-icon>
                <span> Add </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-5">
      <v-btn outlined class="px-5 py-3 text-none" @click="handleEditBtn">
        {{ isEditing ? 'Cancel' : 'Edit' }}
      </v-btn>
      <v-btn
        v-if="isEditing"
        color="primary"
        class="px-5 py-3 text-none text-body-1"
        @click="updateCustomerDetails"
        :loading="saving"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import Loader from '../../components/Loader.vue'
export default {
  data() {
    return {
      isEditing: false,
      updatedName: '',
      currentImage: '',
      imageEdit: false,
      saving: false,
      newEmailDomain: '',
      emailDomains: [],
      mdiPlus,
    }
  },
  computed: {
    ...mapState('customer', ['customer', 'customerImgUrl']),
    ...mapGetters('customer', ['hiringPlanKeys', 'prettyHiringPlans']),
    companyImage() {
      if (!!this.customer?.getCompanyLogoUrl()) {
        return this.customer?.getCompanyLogoUrl()
      }
    },
    hiringPlan() {
      return this.prettyHiringPlans[
        this.hiringPlanKeys[this.customer?.getHiringPlan()]
      ]
    },
    companyDomain() {
      return this.customer?.getCompanyDomain()
    },
  },
  components: { Loader },
  methods: {
    ...mapActions('customer', [
      'fetchCustomer',
      'updateCustomer',
      'uploadCompanyImage',
    ]),
    updateCustomerDetails() {
      if (this.emailDomains.length === 0) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Error! There must be at least 1 email domain.',
          type: 'error',
        })
        return
      }
      this.saving = true
      this.updateCustomer({
        name: this.updatedName,
        imgUrl: this.currentImage,
        emailDomains: this.emailDomains,
      })
        .then((r) => {
          this.imageEdit = false
          this.isEditing = false
          this.saving = false
          this.resetCustomerDetails()
        })
        .catch((err) => {
          console.log('Error updating customer ..', err)
          this.$store.dispatch('notifs/addNotif', {
            text: err.message || 'Updating customer failed, please try again!',
            type: 'error',
          })
          this.isEditing = false
          this.saving = false
          this.resetCustomerDetails()
        })
    },
    addEmailDomain() {
      if (!this.emailDomains.some((domain) => domain === this.newEmailDomain)) {
        this.emailDomains.push(this.newEmailDomain)
      }
      this.newEmailDomain = ''
      this.$refs.emailDomainInput.resetValidation()
    },
    removeEmailDomain(domainIdx) {
      // console.log(domainIdx, this.emailDomains[domainIdx])
      this.emailDomains.splice(domainIdx, 1)
      this.emailDomains = [...this.emailDomains]
    },
    handleEditBtn() {
      this.isEditing = !this.isEditing
      this.resetCustomerDetails()
    },
    resetCustomerDetails() {
      this.updatedName = this.customer?.getName()
      this.currentImage = this.customer?.getCompanyLogoUrl()
      this.emailDomains = this.customer?.getEmailDomainsList()
      if (!!this.emailDomains) {
        this.emailDomains = [...this.emailDomains]
      }
    },
    openImage(event) {
      //console.log("Event .. ", event);
      console.log(this.$refs.avatarFile)
      this.$refs.avatarFile.click()
    },
    async uploadAttachmentSave(event) {
      this.imageEdit = true
      // console.log("files", await this.$refs.avatarFile.files[0].text());
      this.encodeImageFileAsURL(
        await this.$refs.avatarFile,
        this.uploadCompanyImage,
      )
    },
    async encodeImageFileAsURL(element, callback) {
      var file = element.files[0]
      var reader = new FileReader()
      reader.onloadend = function () {
        callback({ image: reader.result.replace(/^data:image.+;base64,/, '') })
      }
      reader.readAsDataURL(file)
    },
  },
  created() {
    if (!this.customer) {
      this.fetchCustomer().then((r) => {
        console.log(`customer`, this.customer?.toObject())
      })
    }
    this.resetCustomerDetails()
    console.log(this.updatedName, this.currentImage, this.emailDomains)
  },
  watch: {
    customer(v) {
      this.resetCustomerDetails()
    },
    customerImgUrl(i) {
      this.currentImage = i
      this.imageEdit = false
    },
  },
}
</script>
<style scoped>
.layer-above {
  z-index: 20;
}
</style>
